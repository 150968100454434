<template>
    <el-main>
        <div class="c_info">
            企业名称:
            <span>{{ c_info.c_name }}</span>
            姓名:
            <span>{{ c_info.name }}</span>
        </div>
        <el-button type="primary" size="small" class="add_goods"
            @click="$router.push(`/extension/gatherTweeters/addCaseGoods?c_name=${c_info.c_name}&c_id=${c_info.id}&name=${c_info.name}`)">
            + 添加商品
        </el-button>
        <el-form class="el-form-search" label-width="90px" v-if="tabs_active == 'first'">
            <el-form-item label="商品状态：">
                <el-select v-model="dataForm.status" size="small" placeholder="请选择">
                    <el-option label="全部" :value="0"></el-option>
                    <el-option label="上架" :value="1"></el-option>
                    <el-option label="下架" :value="-1"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label=" " label-width="15px">
                <el-button type="primary" size="small" @click="get_data_list(1)">搜索</el-button>
                <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
            </el-form-item>
        </el-form>
        <el-tabs v-model="tabs_active" type="card" @tab-click="() => get_data_list(1)">
            <el-tab-pane label="自营商品" name="first"></el-tab-pane>
            <el-tab-pane label="CPS商品" name="second"></el-tab-pane>
        </el-tabs>
        <el-table :data="data_list" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
            <template slot="empty">
                <No />
            </template>
            <el-table-column prop="title" label="商品标题" align="center"></el-table-column>
            <el-table-column prop="sub_title" label="副标题" align="center"></el-table-column>
            <el-table-column label="商品主图" align="center">
                <template v-slot="{ row }">
                    <el-image style="width: 50px; height: 50px"
                        :src="row.head_imgs ? row.head_imgs[0] : 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/orderDefault.png'"
                        :preview-src-list="row.head_imgs"></el-image>
                </template>
            </el-table-column>
            <el-table-column label="商品状态" align="center" v-if="tabs_active == 'first'">
                <template v-slot="{ row }">
                    <el-button size="mini" v-if="row.status == -1" type="primary" @click="statusChange(row)">上架</el-button>
                    <el-button size="mini" v-else-if="row.status == 1" type="danger"
                        @click="statusChange(row)">下架</el-button>
                </template>
            </el-table-column>
            <el-table-column label="商品状态" align="center" v-if="tabs_active == 'second'">
                <template v-slot="{ row }">
                    {{ row.status == -1 ? '下架' : '上架' }}
                </template>
            </el-table-column>
            <el-table-column prop="stock_num" label="总库存" align="center"></el-table-column>
            <el-table-column prop="sales" label="销量" align="center"></el-table-column>
            <el-table-column prop="virtual_sales" label="虚拟销量" align="center"></el-table-column>
            <el-table-column label="创建时间" align="center">
                <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
            </el-table-column>
            <el-table-column label="售卖价格" align="center" prop="sale_price"></el-table-column>
            <el-table-column label="操作" align="center">
                <template v-slot="{ row }">
                    <el-button type="text" v-if="tabs_active == 'first'" @click="editGoods(row)">编辑</el-button>
                    <el-button type="text" v-if="tabs_active == 'first'" @click="startCPS(row)">CPS推广</el-button>
                    <el-button type="text" v-if="tabs_active == 'first'" @click="deleteGoods(row)">删除</el-button>
                    <el-button type="text" v-if="tabs_active == 'second'" @click="setHotFn(row)">{{ row.is_hot ? '取消爆款' :
                        '设置爆款' }}</el-button>
                    <el-button type="text" v-if="tabs_active == 'second'" @click="deleteCpsGoods(row)">删除</el-button>
                    <el-button type="text" v-if="tabs_active == 'first'" @click="copyGoods(row)">复制</el-button>
                </template>
            </el-table-column>
        </el-table>
        <Paging :total="total_number" :page="dataForm.page" :pageNum="dataForm.rows" @updatePageNum="updateData"></Paging>

        <!-- 添加推广商品 -->
        <el-dialog title="推广" :visible.sync="extensionDialogFlag" width="800px">
            <el-table :data="setCpsGoods.skus_cps" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
                <el-table-column align="center" label="商品图片(800*800px)">
                    <template v-slot="{ row }">
                        <el-image v-if="row.thumb_img" style="width: 70px; height: 70px" :src="row.thumb_img"></el-image>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="SKU属性值">
                    <template v-slot="{ row }">
                        {{ row.sku_attrs[0].attr_key }}
                    </template>
                </el-table-column>
                <el-table-column align="center" label="SKU库存" prop="stock_num"></el-table-column>
                <el-table-column align="center" label="SKU价格">
                    <template v-slot="{ row }">
                        <el-input size="small" v-model="row.new_sale_price" type="number"></el-input>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="SKU佣金">
                    <template v-slot="{ row }">
                        <el-input size="small" v-model="row.commission" type="number"></el-input>
                    </template>
                </el-table-column>
            </el-table>
            <span slot="footer" class="dialog-footer">
                <el-button @click="extensionDialogFlag = false">取 消</el-button>
                <el-button type="primary" @click="sureAddExtension">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 复制商品 -->
        <el-dialog title="复制商品" :visible.sync="copyGoodsDialogFlag" width="600px">
            <el-form label-width="100px">
                <el-form-item label="站点-企业：">
                    <el-select v-model="copyGoodsForm.company_id" placeholder="请选择" style="width:80%;">
                        <el-option v-for="(item, index) in company_list" :key="index"
                            :label="item.name + '-' + item.shop_name" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="copyGoodsDialogFlag = false">取 消</el-button>
                <el-button type="primary" @click="copyGoodsConfirm">确 定</el-button>
            </span>
        </el-dialog>
    </el-main>
</template>

<script>
import { getDateformat } from '@/util/getDate.js';
import Paging from '@/components/paging';
import _ from 'lodash';
export default {
    components: {
        Paging,
    },
    created () {
        this.c_info.id = Number(this.$route.query.c_id);
        this.c_info.name = this.$route.query.name;
        this.c_info.c_name = this.$route.query.c_name;
        this.get_data_list();
        this.$axios.post(this.$api.serviceProvider.companyList).then(res => {
            console.log(res);
            if (res.code == 0) {
                this.company_list = res.result;
            }
        });
    },
    data () {
        return {
            getDateformat,
            // 企业信息
            c_info: {
                id: null,
                c_name: '',
                name: '',
            },
            // 列表查询条件
            dataForm: {
                page: 1,
                rows: 10,
                status: 0,
            },
            copyGoodsForm: {
                id: '',
                company_id: '',
            },
            tabs_active: 'first',
            data_list: [],
            company_list: [],
            total_number: 0,
            // 设置cps的商品
            setCpsGoods: {},
            // cps 推广 弹框
            extensionDialogFlag: false,
            copyGoodsDialogFlag: false,
        };
    },
    methods: {
        updateData (val, status) {
            if (status == 0) {
                this.dataForm.rows = val;
            } else {
                this.dataForm.page = val;
            }
            this.get_data_list();
        },
        clearSearch () {
            this.dataForm.status = 0;
            this.get_data_list(1);
        },
        get_data_list (style) {
            if (style) this.dataForm.page = 1;
            let obj = {
                page: this.dataForm.page,
                rows: this.dataForm.rows,
                company_id: this.c_info.id,
            };
            let path = '';
            if (this.tabs_active === 'first') {
                console.log(1);
                obj.status = this.dataForm.status;
                path = this.$api.serviceProvider.companyGoods;
            }
            if (this.tabs_active === 'second') {
                console.log(2);
                path = this.$api.serviceProvider.companyGoodsSkusCps;
            }
            this.$axios.post(path, obj).then(res => {
                if (res.code === 0) {
                    res.result.list.map(item => {
                        item.head_imgs = JSON.parse(item.head_imgs);
                    });
                    this.data_list = res.result.list;
                    this.total_number = res.result.total_number;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        // 上下架商品
        statusChange (data) {
            this.$confirm(`此操作将${data.status == 1 ? '下架' : '上架'}该商品, 是否继续?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    return this.$axios.post(this.$api.serviceProvider.upStatus, {
                        id: data.id,
                        status: data.status == 1 ? -1 : 1,
                    });
                })
                .then(res => {
                    if (res.code == 0) {
                        this.$message.success(`${data.status == 1 ? '下架' : '上架'}成功`);
                        data.status = data.status == 1 ? -1 : 1;
                    } else {
                        this.$message.error(res.msg);
                    }
                })
                .catch(() => { });
        },
        // 点击删除商品
        deleteGoods (data) {
            this.$confirm('此操作将删除该商品, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    return this.$axios.post(this.$api.serviceProvider.delCGoods, {
                        id: data.id,
                    });
                })
                .then(res => {
                    if (res.code == 0) {
                        this.$message.success(`删除成功`);
                        this.get_data_list();
                    } else {
                        this.$message.error(res.msg);
                    }
                })
                .catch(() => { });
        },
        //复制商品
        copyGoods (row) {
            this.copyGoodsDialogFlag = true;
            this.copyGoodsForm.id = row.id;
            this.copyGoodsForm.company_id = '';
        },
        //确认复制商品
        copyGoodsConfirm () {
            if (!this.copyGoodsForm.company_id) return this.$message.warning('请填选择站点-企业');
            this.$axios.post(this.$api.serviceProvider.copyGoods, this.copyGoodsForm).then(res => {
                if (res.code == 0) {
                    this.$message.success('复制成功');
                    this.copyGoodsDialogFlag = false;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        // 编辑商品
        editGoods (data) {
            this.$router.push(`/extension/gatherTweeters/editCaseGoods?c_name=${this.c_info.c_name}&c_id=${this.c_info.id}&name=${this.c_info.name}&g_id=${data.id}`);
        },
        // 点击开始设置cps商品
        startCPS (data) {
            this.$axios
                .post(this.$api.serviceProvider.companyGoodsInfo, {
                    id: data.id,
                })
                .then(res => {
                    if (res.code === 0) {
                        let arr = [];
                        res.result.skus.map(item => {
                            item.sku_attrs = JSON.parse(item.sku_attrs);
                            item.new_sale_price = '';
                            item.commission = '';
                            arr.push(item);
                        });
                        res.result.skus_cps.map((item, index) => {
                            arr[index].commission = item.commission;
                            arr[index].new_sale_price = item.new_sale_price;
                            arr[index].cps_id = item.id;
                        });
                        res.result.skus_cps = arr;
                        this.setCpsGoods = res.result;
                        this.extensionDialogFlag = true;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        // 确认加入添加商品
        sureAddExtension () {
            let falg = true;
            let obj = [];
            this.setCpsGoods.skus_cps.map(item => {
                if (item.commission == '' || item.new_sale_price == '') {
                    falg = false;
                    this.$message.error('请将数据填写完整');
                }
                let son = {
                    goods_id: this.setCpsGoods.id,
                    goods_skus_id: item.id,
                    new_sale_price: item.new_sale_price,
                    commission: item.commission,
                };
                if (item.cps_id) {
                    son.id = item.cps_id;
                }
                obj.push(son);
            });
            if (!falg) return;
            this.$axios.post(this.$api.serviceProvider.CompanyGoodsSkusCpsSubmit, { cps: obj }).then(res => {
                if (res.code == 0) {
                    this.$message.success('设置CPS商品成功');
                    this.extensionDialogFlag = false;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        setHotFn (data) {
            let str = data.is_hot ? '此操作将取消该爆款商品, 是否继续?' : '此操作会将该商品设置成爆款商品, 是否继续?';
            this.$confirm(str, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    return this.$axios.post(this.$api.serviceProvider.upIsHot, {
                        goods_id: data.id,
                        is_hot: data.is_hot ? 0 : 1,
                    });
                })
                .then(res => {
                    if (res.code == 0) {
                        this.$message.success('操作成功');
                        data.is_hot = data.is_hot ? 0 : 1;
                    } else {
                        this.$message.error(res.msg);
                    }
                })
                .catch(() => { });
        },
        deleteCpsGoods (data) {
            this.$confirm('您即将删除该cps商品', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    return this.$axios.post(this.$api.serviceProvider.delCpsGoods, {
                        goods_id: data.id,
                    });
                })
                .then(res => {
                    if (res.code == 0) {
                        this.$message.success('删除成功');
                        this.get_data_list();
                    } else {
                        this.$message.error(res.msg);
                    }
                })
                .catch(() => { });
        },
    },
};
</script>

<style lang="less" scoped>
.el-main {
    min-height: 100%;
    background-color: #fff;

    .c_info {
        font-size: 14px;

        span {
            font-weight: bold;
        }

        span:not(:last-child) {
            margin-right: 50px;
        }
    }

    .add_goods {
        margin: 16px 0;
    }
}
</style>